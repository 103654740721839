export async function catalogProduccion(params) {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet(params.name);
  const data = params.data;
  const INITROW = params.dates ? 5 : 4;
  worksheet.columns = params.columns;
  setTitle();
  setStyleHeadings(worksheet.getRow(INITROW));
  body(data);
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = params.name + ".xlsx";
  anchor.click();
  window.URL.revokeObjectURL(url);

  function setTitle() {
    worksheet.duplicateRow(1, INITROW - 1, true);
    for (let i = 1; i < INITROW; i++) {
      let tempRow = worksheet.getRow(i);
      tempRow.values = [];
      tempRow.font = { bold: true };
    }
    worksheet.getRow(2).values = [`REPORTE DE ${params.name.toUpperCase()}`];
    if (params.dates) {
      worksheet.getRow(3).values = [
        `RANGO DE FECHAS: DEL ${params.dates.inicio} AL ${params.dates.fin}`,
      ];
    }
  }
  function setStyleHeadings(row) {
    row.eachCell(function (cell) {
      // altura
      row.height = 24;
      // relleno
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "004d00" },
      };
      cell.alignment = { vertical: "middle" };
      // Texto
      cell.font = {
        color: { argb: "FFFFFF" },
        bold: true,
      };
      cell.border = {
        right: { style: "thin", color: { argb: "FFFFFF" } },
      };
    });
  }
  function body(data = "") {
    data.forEach((item, index) => {
      let itemSave = {};
      for (let key in item) {
        const { value } = item[key];
        itemSave[key] = value;
      }
      worksheet.insertRow(index + INITROW + 1, {
        ...itemSave,
      });
    });
  }
}
import Excel from "exceljs";

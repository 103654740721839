const { boletasPdf } = require("./formatos");

const formatos = { 1: boletasPdf };

export const generatePdf = (data) => {
  return new Promise((resolve, reject) => {
    async function init(params) {
      /*global PDFDocument, blobStream*/
      /*eslint no-undef: "error"*/
      params.pdf = new PDFDocument({
        autoFirstPage: false,
        bufferPages: true,
      });

      params.pdfString = "";
      const stream = params.pdf.pipe(blobStream());
      params.pdf.on("pageAdded", async () => {
        params.paginas++;
      });

      stream.on("finish", function () {
        const blob = stream.toBlob("application/pdf");
        const objectUrl = URL.createObjectURL(blob);
        resolve(objectUrl);
      });

      //Agregar await
      const formato = formatos[params.generatePdf];
      await formato(params);

      params.pdf.flushPages();
      params.pdf.end();
    }
    try {
      let params = { ...data };

      init(params);
    } catch (error) {
      reject(error);
    }
  });
};

<template>
  <v-dialog v-model="dialog" max-width="400px" persistent>
    <v-card>
      <v-card-title class="secondary">
        <v-icon color="pla_c3"> {{ iconTitle }} </v-icon>
        <span class="text-h6 pl-3 white--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="py-2 px-5">
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row no-gutters class="pt-5">
              <v-col cols="12">
                <v-menu
                  v-model="menuFecha"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaFormat"
                      label="Fecha inicio"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.proprFecha"
                    :first-day-of-week="0"
                    locale="es"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  hint="Busca por nombre"
                  persistent-hint
                  :items="procesosItems"
                  v-model="item.prprcId"
                  :filter="procesosFilter"
                  item-text="prprcNombre"
                  item-value="prprcId"
                  label="Proceso"
                  dense
                  outlined
                  no-data-text="No se encontraron empleados."
                  :rules="noEmptyRules"
                >
                  <template v-slot:selection="data">
                    <small>{{ data.item.prprcNombre }}</small>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>
                          <small class="font-weight-bold">
                            {{ data.item.prprcNombre }}
                          </small>
                        </v-list-item-title>
                        <!--
                        <v-list-item-subtitle>
                          <small>
                            <span class="font-weight-bold">elemento:</span>
                            {{ data.item.prprcNombre }}
                          </small>
                        </v-list-item-subtitle>
                        -->
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  autofocus
                  label="Referencia"
                  hide-details="auto"
                  v-model="item.proprReferencia"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn @click="close" :loading="loadingSave" color="support" outlined>
          Cancelar
        </v-btn>
        <v-btn
          @click="save"
          elevation="0"
          :loading="loadingSave"
          color="primary"
        >
          {{ buttonTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { dateToUnix, now } from "../../core/utils";

export default {
  name: "OrdenProduccionForm",
  props: {
    itemIndex: Number,
    itemParam: Object,
  },
  data: () => ({
    valid: false,
    item: {
      proprFecha: now(),
      proprFechaFin: "",
      proprReferencia: "",
      prprcId: "",
    },
    noEmptyRules: [(value) => !!value || "Completa este dato."],
    menuFecha: false,
    dialog: true,
  }),
  computed: {
    ...mapState({
      loadingSave: (state) => state.ordenesProduccion.loadingSave,
      loadingProcesos: (state) => state.tprocesos.loading,
      procesosItems: (state) => state.tprocesos.procesos,
    }),
    formTitle() {
      return this.itemIndex === -1
        ? "Nueva Orden de trabajo"
        : "Editar Orden de trabajo";
    },
    buttonTitle() {
      return this.itemIndex === -1 ? "Guardar" : "Actualizar";
    },
    fechaFormat: function () {
      var datePart = this.item.proprFecha.match(/\d+/g),
        year = datePart[0],
        month = datePart[1],
        day = datePart[2];
      return day + "-" + month + "-" + year;
    },
    iconTitle() {
      return this.itemIndex === -1 ? "mdi-plus" : "mdi-pen";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    ...mapActions({
      createOrdenProduccion: "ordenesProduccion/createOrdenProduccion",
      updateOrdenProduccion: "ordenesProduccion/updateOrdenProduccion",
      alert: "alerts/setMessage",
    }),
    save() {
      if (!this.$refs.form.validate()) return;
      if (this.itemIndex > -1) {
        this.updateItem();
      } else {
        this.createItem();
      }
    },
    async createItem() {
      try {
        const data = {
          proprFecha: dateToUnix(this.item.proprFecha),
          proprReferencia: this.item.proprReferencia,
          prprcId: +this.item.prprcId,
          proprStatus: "GRA",
        };
        await this.createOrdenProduccion({
          data: data,
        });
        this.close();
        this.alert({
          message: "Se ha creado la orden de producción",
          type: "success",
        });
      } catch (e) {
        this.alert({
          message:
            "ha ocurrido un error al momento de crear la orden de producción.",
          type: "error",
        });
      }
    },
    async updateItem() {
      try {
        const data = {
          proprId: +this.item.proprId,
          data: {
            proprFecha: dateToUnix(this.item.proprFecha),
            proprReferencia: this.item.proprReferencia,
            prprcId: +this.item.prprcId,
          },
        };
        await this.updateOrdenProduccion({
          proprId: +this.item.proprId,
          data: data,
        });
        this.close();
        this.alert({
          message: "Se ha actualizado la orden de producción",
          type: "success",
        });
      } catch (e) {
        this.alert({
          message:
            "ha ocurrido un error al momento de actualizar la orden de producción.",
          type: "error",
        });
      }
    },
    procesosFilter(item, queryText) {
      const fname = item.prprcNombre.toLowerCase();
      const searchText = queryText.toLowerCase();
      /*return (
        fname.indexOf(searchText) > -1 ||
        lname.indexOf(searchText) > -1
      );*/
      return fname.indexOf(searchText) > -1;
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    this.item = this.itemParam;
  },
};
</script>

<style scoped></style>

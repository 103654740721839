import { milisecondsToDate } from "@/core/utils";

const milisecondsToDates = (miliseconds) => {
  return milisecondsToDate(miliseconds);
};

const findProcesoName = (procesoId, procesosItems) => {
  let procesId = String(procesoId);
  try {
    return procesosItems.find((p) => p.prprcId == procesId).prprcNombre;
  } catch (error) {
    return "No encontrado";
  }
};

export async function boletasPdf(params) {
  params.pdf.addPage({
    size: "A4",
    info: {
      Title: "Boletas",
      Subject: "Impresión de producción",
      Author: "Software en la Nube Salmo 1, S. A.",
    },
  });
  let page = 1;
  params.items.forEach((element) => {
    // DIBUJA UN RECTANGULO
    params.pdf.rect(5, 5, 287, 200, "S");
    params.pdf.fontSize(14);
    params.pdf.font("Helvetica-Bold");
    /*pdf.text(establishmentName, 10, 15);*/
    params.pdf.text("Establecimiento", 10, 15);
    params.pdf.text("Orden de Producción " + element.proprNumero, 220, 15);
    // HEADER LEFT SIDE
    params.pdf.fontSize("Helvetica");
    params.pdf.text("CODIGO:", 10, 30);
    params.pdf.text("PUESTO:", 10, 40);
    params.pdf.text("CONCEPTO:", 10, 45);

    params.pdf.text(element.proprId, 70, 30);
    params.pdf.fontSize("Helvetica");
    params.pdf.text("Colaborador", 70, 40);
    params.pdf.text("Fecha: " + milisecondsToDates(element.proprFecha), 70, 45);
    // DETALLE DE LA BOLETA
    // TITULO DEL DETALLE
    params.pdf.font("Helvetica-Bold");
    params.pdf.text("DETALLE DE LA ORDEN DE PRODUCCION", 110, 55);
    // CUERPO IZQUIERDO DE LA BOLETA
    params.pdf.fontSize("Helvetica");
    params.pdf.text("Correlativo:", 10, 65);
    params.pdf.text("Referencia:", 10, 70);
    params.pdf.text("Proceso:", 10, 75);

    params.pdf.text(element.proprCorrelativo, 90, 65);
    params.pdf.text(element.proprReferencia, 90, 70);
    params.pdf.text(
      findProcesoName(element.prprcId, params.procesosItems),
      90,
      75
    );

    params.pdf.text("Firma: ___________________________________", 160, 165);
    params.pdf.font("Helvetica-Bold");
    params.pdf.text(
      "Elaborado           Autorizado           Vo. Bo.",
      10,
      175
    );

    params.pdf.text("Agritec GEO", 10, 198);

    page++;
    let compare = page - 1;
    if (compare != params.items.length) {
      params.pdf.addPage();
    }
  });
}

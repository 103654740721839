<template>
  <v-dialog v-model="dialog" max-width="600px" persistent scrollable>
    <v-card>
      <v-card-title class="secondary">
        <v-icon color="pla_c3"> mdi-package-variant-closed-check </v-icon>
        <span class="text-h6 pl-3 white--text">
          {{ nameOrdenProduccion }}
        </span>
      </v-card-title>
      <v-card-text class="py-2 px-5">
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-img
                  :src="'/img/icons/custom/file-orden-produccion.png'"
                  max-height="150"
                  class="pb-5 mt-5"
                  contain
                />
                <h3
                  class="
                    primary--text
                    text-md-h6
                    font-weight-bold
                    text-center
                    py-5
                  "
                >
                  ¿Deseas cerrar la orden de producción?
                </h3>
                <h3 class="font-weight-medium text-md-subtitle-2">
                  Al cerrar la orden de producción se procederá a crear un
                  registro en el inventario.
                </h3>
              </v-col>
              <v-col cols="12">
                <v-expansion-panels flat popout hover>
                  <v-expansion-panel
                    :key="0"
                    active-class="grey lighten-5 soft_shadow"
                  >
                    <v-expansion-panel-header>
                      <h3>
                        <v-icon color="primary" left> mdi-reproduction </v-icon>
                        <span class="grey--text text--darken-3">
                          {{ "Productos de salida" }}
                        </span>
                      </h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="pt-5">
                        <v-col cols="12">
                          <v-data-table
                            :headers="headersPSalida"
                            :items="productosSalida"
                            :items-per-page="5"
                            :header-props="{ 'sort-by-text': 'Ordenar por' }"
                            :footer-props="{
                              'items-per-page-text': 'Productos por página',
                              'items-per-page-all-text': 'Todos',
                            }"
                            :loading="productosSalidaLoading"
                          >
                            <!-- slots defaults -->
                            <template v-slot:top> </template>
                            <template v-slot:no-data>
                              <my-table-no-data
                                title="¡Lo sentimos!"
                                text="Aun no existe algun Producto de salida."
                              />
                            </template>
                            <template v-slot:no-results>
                              <my-table-no-data
                                title="¡Lo sentimos!"
                                text="No encontramos ningun Producto de salida."
                              />
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn
          @click="close"
          :loading="ordenesProduccionLoadingSave"
          color="support"
          outlined
        >
          Cancelar
        </v-btn>
        <v-btn
          @click="save"
          elevation="0"
          :loading="ordenesProduccionLoadingSave"
          color="primary"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import myTableNoData from "../ui/datatable/myTableNoData";
export default {
  name: "OrdenTrabajoClose",
  props: {
    itemIndex: Number,
    itemParam: Object,
  },
  components: {
    myTableNoData,
  },
  data: function () {
    return {
      dialog: true,
      panel: 0,
      nameOrdenProduccion: "",
      headersPSalida: [
        { text: "Nombre", value: "sinv_pro_descripcion" },
        { text: "Peso", value: "propsPeso" },
      ],
    };
  },
  computed: {
    ...mapState({
      productosSalida: (state) =>
        state.productosSalida.productosSalidaProduccion,
      productosSalidaLoading: (state) => state.productosSalida.loading,
      ordenesProduccionLoadingSave: (state) =>
        state.ordenesProduccion.loadingSave,
    }),
  },
  methods: {
    ...mapActions({
      cerrarOrdenProduccion: "ordenesProduccion/cerrarOrdenProduccion",
    }),
    close() {
      this.$emit("close");
    },
    async save() {
      if (!this.itemParam) return;
      const data = {
        proprId: +this.itemParam.proprId,
      };
      await this.cerrarOrdenProduccion({
        data: data,
      });
      this.close();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.nameOrdenProduccion = localStorage.getItem("prprcNombre");
    this.$store.dispatch("productosSalida/getOrdenesProduccionSalida");
  },
};
</script>

<style scoped></style>
<style scoped src="@/assets/css/globals.css"></style>

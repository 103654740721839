var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":""}},[_c('my-header-view',{attrs:{"title":"Ordenes de trabajo","icon":"mdi-clipboard-text-clock-outline"}}),(_vm.loadingProcesos)?_c('my-loader',{attrs:{"title":"Un momento por favor","subtitle":"Estamos obteniendo los datos necesarios."}}):_c('v-row',{staticClass:"pt-5",attrs:{"row":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 mt-5 px-md-4 soft_shadow",attrs:{"headers":_vm.headers,"items":_vm.itemsOrdenesProduccion,"items-per-page":5,"header-props":{ 'sort-by-text': 'Ordenar por' },"footer-props":{
          'items-per-page-text': 'Ordenes de trabajo por página',
          'items-per-page-all-text': 'Todos',
        },"loading":_vm.loadingOrdenesProduccion,"loading-text":"Obteniendo registros"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('my-table-top',{attrs:{"custom-top":""},scopedSlots:_vm._u([{key:"custom",fn:function(){return [_c('v-col',{attrs:{"cols":"12","md":"9","lg":"10"}}),_c('v-col',{attrs:{"cols":"12","md":"3","lg":"2","align":"right"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('sync-info',{attrs:{"title":"las ordenes de trabajo","loading":_vm.loading,"id":"ordenesProduccion/findOrdenesProduccion"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('my-table-options',{attrs:{"include-create":"","includePdf":"","include-report":"","create-text":"Nueva orden de trabajo","pdfText":"Boletas a PDF"},on:{"optionReport":_vm.createReport,"optionPdf":_vm.createPdf,"optionCreate":_vm.openCreateForm}})],1)],1)],1)]},proxy:true}])})]},proxy:true},{key:"no-data",fn:function(){return [_c('my-table-no-data',{attrs:{"title":"No existe ninguna orden de trabajo","text":"agrega una"}})]},proxy:true},{key:"no-results",fn:function(){return [_c('my-table-no-data',{attrs:{"title":"¡Lo sentimos!","text":"No encontramos ninguna orden de trabajo."}})]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('my-table-item-actions',{attrs:{"item":item},on:{"edit-item":_vm.editItem,"enable-item":_vm.enableItem,"disable-item":_vm.disableItem},scopedSlots:_vm._u([{key:"preActions",fn:function(){return [_c('v-list-item',{on:{"click":function($event){return _vm.viewDetails(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary","small":"","right":""}},[_vm._v(" mdi-text-box-search-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Detalle de orden de producción ")])],1)],1),(item.proprStatus === 'GRA')?_c('v-list-item',{on:{"click":function($event){return _vm.handleOrdenTrabajoClose(true, item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary","small":"","right":""}},[_vm._v(" mdi-package-variant-closed-check ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Cerrar orden de producción ")])],1)],1):_vm._e()]},proxy:true}],null,true)})]}},{key:"item.proprFecha",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.milisecondsToDate(item.proprFecha))+" ")]}},{key:"item.proprFechaFin",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.milisecondsToDate(item.proprFechaFin))+" ")]}},{key:"item.proprReferencia",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.proprReferencia || "---")+" ")]}},{key:"item.prprcId",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.findProcesoName(item.prprcId))+" ")]}},{key:"item.proprStatus",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{class:_vm.ordenesTrabajoStatusAsset[item.proprStatus].class,attrs:{"small":"","color":_vm.ordenesTrabajoStatusAsset[item.proprStatus].color}},[_vm._v(" "+_vm._s(item.proprStatus ? _vm.ordenesTrabajoStatusItems[item.proprStatus] : "---")+" ")])]}},{key:"item.sadm_eliminado",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.sadm_eliminado ? "Anulada" : "Activa")+" ")]}}],null,true)})],1)],1),(_vm.dialogForm)?_c('orden-produccion-form',{attrs:{"itemIndex":_vm.editedIndex,"itemParam":_vm.editedItem},on:{"close":_vm.close}}):_vm._e(),(_vm.dialogOrdenTrabajoClose)?_c('orden-trabajo-close',{attrs:{"itemIndex":_vm.editedIndex,"itemParam":_vm.editedItem},on:{"close":function($event){return _vm.handleOrdenTrabajoClose(false, {})}}}):_vm._e(),_c('my-table-dialog-delete',{attrs:{"title":_vm.ordenProduccionDeleteTitle,"loadingSave":_vm.loadingSaveOrdenProduccion,"dialogDelete":_vm.dialogDelete},on:{"change-state":_vm.changeStateItemConfirm,"close-delete":_vm.closeDelete,"dialog-delete":function (val) { return (_vm.dialogDelete = val); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
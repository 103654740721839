<template>
  <div id="" class="">
    <my-header-view
      title="Ordenes de trabajo"
      icon="mdi-clipboard-text-clock-outline"
    />
    <my-loader
      v-if="loadingProcesos"
      title="Un momento por favor"
      subtitle="Estamos obteniendo los datos necesarios."
    />
    <v-row v-else row class="pt-5">
      <v-col cols="12">
        <!--
        tem para paginacion
        :options.sync="options"
        :server-items-length="totalItems"
        -->
        <v-data-table
          :headers="headers"
          :items="itemsOrdenesProduccion"
          class="elevation-0 mt-5 px-md-4 soft_shadow"
          :items-per-page="5"
          :header-props="{ 'sort-by-text': 'Ordenar por' }"
          :footer-props="{
            'items-per-page-text': 'Ordenes de trabajo por página',
            'items-per-page-all-text': 'Todos',
          }"
          :loading="loadingOrdenesProduccion"
          loading-text="Obteniendo registros"
        >
          <!-- slots defaults -->
          <template v-slot:top>
            <my-table-top custom-top>
              <template v-slot:custom>
                <v-col cols="12" md="9" lg="10"> </v-col>
                <v-col cols="12" md="3" lg="2" align="right">
                  <v-row no-gutters>
                    <v-col cols="4">
                      <sync-info
                        title="las ordenes de trabajo"
                        :loading="loading"
                        id="ordenesProduccion/findOrdenesProduccion"
                      />
                    </v-col>
                    <v-col cols="8">
                      <my-table-options
                        include-create
                        includePdf
                        include-report
                        create-text="Nueva orden de trabajo"
                        pdfText="Boletas a PDF"
                        @optionReport="createReport"
                        @optionPdf="createPdf"
                        @optionCreate="openCreateForm"
                      >
                      </my-table-options>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
            </my-table-top>
          </template>
          <template v-slot:no-data>
            <my-table-no-data
              title="No existe ninguna orden de trabajo"
              text="agrega una"
            />
          </template>
          <template v-slot:no-results>
            <my-table-no-data
              title="¡Lo sentimos!"
              text="No encontramos ninguna orden de trabajo."
            />
          </template>
          <!-- slots defaults end -->
          <template v-slot:[`item.actions`]="{ item }">
            <my-table-item-actions
              :item="item"
              @edit-item="editItem"
              @enable-item="enableItem"
              @disable-item="disableItem"
            >
              <template v-slot:preActions>
                <v-list-item @click="viewDetails(item)">
                  <v-list-item-icon>
                    <v-icon color="primary" small right>
                      mdi-text-box-search-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Detalle de orden de producción
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="item.proprStatus === 'GRA'"
                  @click="handleOrdenTrabajoClose(true, item)"
                >
                  <v-list-item-icon>
                    <v-icon color="primary" small right>
                      mdi-package-variant-closed-check
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Cerrar orden de producción
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </my-table-item-actions>
          </template>
          <template v-slot:[`item.proprFecha`]="{ item }">
            {{ milisecondsToDate(item.proprFecha) }}
          </template>
          <template v-slot:[`item.proprFechaFin`]="{ item }">
            {{ milisecondsToDate(item.proprFechaFin) }}
          </template>
          <template v-slot:[`item.proprReferencia`]="{ item }">
            {{ item.proprReferencia || "---" }}
          </template>
          <template v-slot:[`item.prprcId`]="{ item }">
            {{ findProcesoName(item.prprcId) }}
          </template>
          <template v-slot:[`item.proprStatus`]="{ item }">
            <v-chip
              small
              :color="ordenesTrabajoStatusAsset[item.proprStatus].color"
              :class="ordenesTrabajoStatusAsset[item.proprStatus].class"
            >
              {{
                item.proprStatus
                  ? ordenesTrabajoStatusItems[item.proprStatus]
                  : "---"
              }}
            </v-chip>
          </template>
          <template v-slot:[`item.sadm_eliminado`]="{ item }">
            {{ item.sadm_eliminado ? "Anulada" : "Activa" }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <orden-produccion-form
      v-if="dialogForm"
      :itemIndex="editedIndex"
      :itemParam="editedItem"
      @close="close"
    />
    <orden-trabajo-close
      v-if="dialogOrdenTrabajoClose"
      :itemIndex="editedIndex"
      :itemParam="editedItem"
      @close="handleOrdenTrabajoClose(false, {})"
    />

    <my-table-dialog-delete
      :title="ordenProduccionDeleteTitle"
      :loadingSave="loadingSaveOrdenProduccion"
      :dialogDelete="dialogDelete"
      @change-state="changeStateItemConfirm"
      @close-delete="closeDelete"
      @dialog-delete="(val) => (dialogDelete = val)"
    ></my-table-dialog-delete>
  </div>
</template>

<script>
import myHeaderView from "../ui/myHeaderView";
import myLoader from "../ui/myLoader";
import myTableTop from "../ui/datatable/myTableTop";
import myTableNoData from "../ui/datatable/myTableNoData";
import myTableItemActions from "../ui/datatable/myTableItemActions";
import myTableOptions from "../ui/datatable/myTableOptions";
import SyncInfo from "../Home/SyncInfo";
import myTableDialogDelete from "../ui/datatable/myTableDialogDelete";
import { mapActions, mapState } from "vuex";
import { now, milisecondsToDate, milisecondsToInput } from "../../core/utils";
import { generatePdf, catalogProduccion } from "../../utils";
import OrdenProduccionForm from "../Forms/OrdenProduccionForm";
import OrdenTrabajoClose from "./OrdenTrabajoClose";
import {
  getOrdenesTrabajoStatuAssset,
  getordenesTrabajoStatus,
} from "../../helpers";
export default {
  name: "OrdenesTrabajoList",
  components: {
    OrdenTrabajoClose,
    myHeaderView,
    myLoader,
    myTableTop,
    myTableNoData,
    myTableItemActions,
    myTableOptions,
    OrdenProduccionForm,
    SyncInfo,
    myTableDialogDelete,
  },
  data: () => ({
    dialogForm: false,
    dialogDelete: false,
    dialogOrdenTrabajoClose: false,
    //tabla
    headers: [
      { text: "Fecha", value: "proprFecha" },
      /*
      { text: "Fecha Cierre", value: "proprFechaFin" },
*/
      { text: "Numero", value: "proprNumero" },
      { text: "Correlativo", value: "proprCorrelativo" },
      { text: "Referencia", value: "proprReferencia" },
      { text: "Proceso", value: "prprcId" },
      { text: "Etapa", value: "proprStatus" },
      { text: "Estado", value: "sadm_eliminado" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      proprFecha: now(),
      proprFechaFin: "",
      proprReferencia: "",
      prprcId: "",
    },
    defaultItem: {
      proprFecha: now(),
      proprFechaFin: "",
      proprReferencia: "",
      prprcId: "",
    },
    loading: false,
    menuFechaInicio: false,
    menuFechaFin: false,
    dataFilter: {
      fechaInicio: now(),
      fechaFin: now(),
      prprcId: null,
    },
    estado: 0,
    ordenesTrabajoStatusItems: getordenesTrabajoStatus,
    ordenesTrabajoStatusAsset: getOrdenesTrabajoStatuAssset,
    //paginacion
    /*
    itemsOrdenesProduccion: [],
    options: {},
    numberOfPages: 0,
    totalItems: 0,*/
  }),
  computed: {
    ...mapState({
      loadingOrdenesProduccion: (state) => state.ordenesProduccion.loading,
      loadingSaveOrdenProduccion: (state) =>
        state.ordenesProduccion.loadingSave,
      itemsOrdenesProduccion: (state) =>
        state.ordenesProduccion.ordenesProduccion,
      loadingProcesos: (state) => state.tprocesos.loading,
      procesosItems: (state) => state.tprocesos.procesos,
    }),
    fechaInicioFormat: function () {
      var datePart = this.dataFilter.fechaInicio.match(/\d+/g),
        year = datePart[0],
        month = datePart[1],
        day = datePart[2];
      return day + "-" + month + "-" + year;
    },
    fechaFinFormat: function () {
      var datePart = this.dataFilter.fechaFin.match(/\d+/g),
        year = datePart[0],
        month = datePart[1],
        day = datePart[2];
      return day + "-" + month + "-" + year;
    },
    ordenProduccionDeleteTitle() {
      return this.estado === 0
        ? "¿Desea activar esta orden de trabajo?"
        : "¿Desea anular esta orden de trabajo?";
    },
  },
  methods: {
    ...mapActions({
      findOrdenesProduccion: "ordenesProduccion/findOrdenesProduccion",
    }),
    findProcesoName(procesoId) {
      try {
        return this.procesosItems.find((p) => p.prprcId == procesoId)
          .prprcNombre;
      } catch (error) {
        return "No encontrado";
      }
      //return "asdfsd" + procesoId;
    },
    changeStateItemConfirm() {
      let data = {
        proprId: this.editedItem.proprId,
        estado: this.estado === 1,
      };
      this.$store
        .dispatch("ordenesProduccion/deleteOrdenProduccion", {
          data,
        })
        .then(() => (this.dialogDelete = false))
        .catch((error) => console.log(error));
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    enableItem(item) {
      this.estado = 0;
      this.editedIndex = this.itemsOrdenesProduccion.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    disableItem(item) {
      this.estado = 1;
      this.editedIndex = this.itemsOrdenesProduccion.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    milisecondsToDate(seconds) {
      return milisecondsToDate(seconds);
    },
    viewDetails(row) {
      localStorage.setItem(
        `prprcNombre`,
        row.proprNumero + " " + this.findProcesoName(row.prprcId)
      );
      localStorage.setItem(`proprId`, row.proprId);
      localStorage.setItem(`prprcId`, row.prprcId);
      this.$router.push({
        name: "OtDetail",
        params: { id: row.prprcId },
      });
    },
    procesosFilter(item, queryText) {
      const fname = item.prprcNombre.toLowerCase();
      const searchText = queryText.toLowerCase();
      return fname.indexOf(searchText) > -1;
    },
    openCreateForm() {
      this.dialogForm = true;
    },
    editItem(item) {
      this.editedIndex = this.itemsOrdenesProduccion.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.prprcId = item.prprcId.toString();
      this.editedItem.proprFecha = milisecondsToInput(item.proprFecha);
      this.openCreateForm();
    },
    close() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    handleOrdenTrabajoClose(value, item) {
      if (value === true) {
        this.editedIndex = this.itemsOrdenesProduccion.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.editedItem.prprcId = item.prprcId.toString();
        this.editedItem.proprFecha = milisecondsToInput(item.proprFecha);
        this.dialogOrdenTrabajoClose = value;

        localStorage.setItem(
          `prprcNombre`,
          item.proprNumero + " " + this.findProcesoName(item.prprcId)
        );
        localStorage.setItem(`proprId`, item.proprId);
        localStorage.setItem(`prprcId`, item.prprcId);
      } else {
        this.dialogOrdenTrabajoClose = value;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      }
    },
    createPdf() {
      const data = {
        items: this.itemsOrdenesProduccion,
        generatePdf: "1",
        procesosItems: this.procesosItems,
      };
      generatePdf(data);
    },
    createReport() {
      const params = {
        name: "Boletas de Produccion",
        moneda: "GTQ",
        columns: [
          { header: "Correlativo", key: "correlativo", width: 18 },
          { header: "Numero", key: "numero", width: 18 },
          { header: "Referencia", key: "referencia", width: 18 },
          { header: "Proceso", key: "proceso", width: 18 },
          { header: "Creacion", key: "creacion", width: 18 },
          { header: "Modificacion", key: "modificacion", width: 18 },
        ],
        data: this.getData(),
        produccion: "p",
      };
      catalogProduccion(params);
    },
    getData() {
      return this.itemsOrdenesProduccion.map((item) => ({
        correlativo: {
          value: item.proprCorrelativo,
          type: "text",
          column: "A",
        },
        numero: {
          value: item.proprNumero,
          type: "text",
          column: "B",
        },
        referencia: {
          value: item.proprReferencia,
          type: "text",
          column: "C",
        },
        proceso: {
          value: this.findProcesoName(item.prprcId),
          type: "text",
          column: "D",
        },
        creacion: {
          value: milisecondsToDate(item.sadm_fecha_creado),
          type: "date",
          column: "E",
        },
        modificacion: {
          value: milisecondsToDate(item.sadm_fecha_modificado),
          type: "text",
          column: "F",
        },
      }));
    },
  },
  mounted() {
    this.$store.dispatch("tprocesos/findProcesos");
    this.findOrdenesProduccion();
  },
};
</script>

<style scoped></style>
<style scoped src="@/assets/css/globals.css"></style>
